<template>
    <div>
        <div class="member-right" style="background-color: #fff;padding: 20px;min-height: 600px;">
            <div class="nav-title">
                <div>个人信息</div>
            </div>
            <div class="user-info ">
                <el-form :model="info" :rules="rules" ref="info" label-width="85px" label-position="left"
                    class="upload-form">
                    <el-form-item label="昵称" prop="nickname">
                        <el-col :span="12">
                            <el-input v-model="info.nickname" placeholder="请输入昵称"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-col :span="12">
                            <el-input v-model="info.name" placeholder="请输入姓名"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="info.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
                <!-- <div class="user-info-item">
                    <div class="item-title">昵称</div>
                    <div class="item-val">
                        <input type="text" placeholder="填写昵称">
                    </div>
                </div>
                <div class="user-info-item">
                    <div class="item-title">姓名</div>
                    <div class="item-val">
                        <input type="text" placeholder="填写姓名">
                    </div>
                </div>
                <div class="user-info-item">
                    <div class="item-title">性别</div>
                    <div class="item-val radiogroup">
                        <div class="group">
                            <el-radio v-model="sex" :label=1>男</el-radio>
                        </div>
                        <div class="group">
                            <el-radio v-model="sex" :label=2>女</el-radio>
                        </div>
                    </div>
                </div>
                <div class="buy-btn">提交</div> -->
                <!--  -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sex: 1,
                info: {
                    nickname: '',
                    name: '',
                    sex: 1,
                },
                rules: {
                    nickname: [{
                        required: true,
                        message: '请输入昵称',
                        trigger: 'blur'
                    }, ],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }],
                    img_list: [{
                        required: true,
                    }],
                    sex: [{
                        required: true,
                        message: '请选择性别',
                        trigger: 'change'
                    }],
                }
            }
        },
        methods: {
            submitForm(formName) {
                console.log(this.info.sex)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
        mounted() {

        },
    };
</script>

<style lang="less" scoped>

</style>